export function init() {
  const articles = document.querySelectorAll(".c-article .c-article__post-content");
  if(articles.length === 0) return;

  const observerMain = articles[0].closest(".c-observer__main");

  articles.forEach((article) => {
    const tables = article.querySelectorAll("table");
    // 製品詳細ページはタブレットサイズ以下で横スクロールできるようにする
    const className = observerMain ? "c-table--scroll-tb" : "c-table--scroll-sp";

    tables.forEach((table) => {
      createTableWrapper(table, className);
    });
  })

  function createTableWrapper(table, className) {
    const tableWrapper = document.createElement("div");
    tableWrapper.className = `c-table ${className}`;
  
    const scrollCaption = document.createElement("p");
    scrollCaption.className = "c-table__scroll-caption";
    scrollCaption.textContent = "横スクロールでご覧いただけます.";
  
    const containerDiv = document.createElement("div");
    containerDiv.className = "c-table__container";
  
    tableWrapper.appendChild(scrollCaption);
    tableWrapper.appendChild(containerDiv);
    table.parentNode.insertBefore(tableWrapper, table);
    containerDiv.appendChild(table);
  }
}

/**
 * サブメニューのHTMLを作成する
 */
export function createSubMenuItem(element, targetList) {
  const textContent = element.textContent.trim(); // テキストの先頭と末尾の余分な空白を削除
  const idValue = textContent.replace(/\s+/g, "-").toLowerCase(); // スペースをハイフンに置換し、小文字に変換

  const newListItem = document.createElement("li");
  newListItem.className = "c-submenu-item";

  const newLink = document.createElement("a");
  newLink.className = "js-observer-nav";
  newLink.href = `#${idValue}`;

  const newSpan = document.createElement("span");
  newSpan.className = "c-submenu-item__label";
  newSpan.textContent = textContent;

  newLink.appendChild(newSpan);
  newListItem.appendChild(newLink);
  targetList.appendChild(newListItem);
}