export function init() {
  const navItems = document.querySelectorAll('.c-header-menu-sp__item');
  if(!navItems.length === 0) return;

  /**
   * 副項目を持つメニュー項目に`has-chilredn`クラスを付与
   */
  navItems.forEach(navItem => {
    const navLabel = navItem.querySelector('.c-header-menu-sp__label');
    const content = navLabel.nextElementSibling;
    if(!content) return;
    navItem.classList.add('has-children');
  });

  /**
   * 副項目を持つメニュー項目にアコーディオンメニューのクラスを付与
   */
  navItems.forEach(navItem => {
    const navLabel = navItem.querySelector('.c-header-menu-sp__label');
    const content = navLabel.nextElementSibling;
    if(!content) return;
    navLabel.classList.add('c-accordion-trigger');
    content.classList.add('c-accordion-wrapper');
  });
}
