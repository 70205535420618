export function init() {
  const navItems = document.querySelectorAll('.c-header__inner--sp .menu-global-menu-list > .menu-item');
  if(!navItems.length === 0) return;

  /**
   * 副項目を持つメニュー項目にアコーディオンメニューのクラスを付与
   */
  navItems.forEach(navItem => {
    if(!navItem.classList.contains('menu-item-has-children')) return;
    const navLink = navItem.querySelector('a');
    const content = navItem.querySelector('.sub-menu');

    // aタグをdivタグで囲む
    const navLinkWrap = document.createElement("div");
    navLinkWrap.className = "nav-link-wrap";
    navLink.parentNode.insertBefore(navLinkWrap, navLink);
    navLinkWrap.appendChild(navLink);

    navLinkWrap.classList.add('c-accordion-trigger');
    content.classList.add('c-accordion-wrapper');
  });
}
