import { createSubMenuItem } from './addTag';

export function init() {
  const productDetail = document.querySelector('.c-product-detail');
  if(productDetail === null) return;
  const subMenuList = document.querySelector(".c-submenu__list");

  const headings = productDetail.querySelectorAll('.c-article h2');
  headings.forEach((heading) => {
    createSubMenuItem(heading, subMenuList);
    setId(heading);
  })

  const sections = document.querySelectorAll(".c-section");
  sections.forEach((section) => {
    const sectionHeading = section.querySelector("h2");
    createSubMenuItem(sectionHeading, subMenuList);
    setId(sectionHeading);
  })

  const subMenuItem = subMenuList.querySelectorAll(".c-submenu-item");
  subMenuItem[0].classList.add("is-active");

  /**
   * 見出しにidを追加する
   */
  function setId(element) {
    const textContent = element.textContent.trim(); // テキストの先頭と末尾の余分な空白を削除
    const idValue = textContent.replace(/\s+/g, "-").toLowerCase(); // スペースをハイフンに置換し、小文字に変換
    element.setAttribute("id", idValue);
  }
}