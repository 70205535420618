export function init() {
  const observer = document.querySelector('.c-observer');
  if(observer === null) return;
  const buttons = observer.querySelectorAll('.js-observer-nav');
  const ACTIVE_CLASS_NAME = 'is-active';
  const dataRootMargin = observer.getAttribute('data-rootmargin');
  const rootMarginValue = dataRootMargin !== null ? dataRootMargin : "-50%";

  buttons.forEach(button => {
    const target = document.querySelector(button.getAttribute('href'));
    const observer = new IntersectionObserver(([{isIntersecting}]) => {
      if (isIntersecting) {
        buttons.forEach(el => 
          el.parentNode.classList.remove(ACTIVE_CLASS_NAME)
        );
        button.parentNode.classList.add(ACTIVE_CLASS_NAME);
      }
    }, {
      rootMargin: rootMarginValue,
    });

    observer.observe(target);
  })
}