export function init() {
  const HamburgerButton = document.querySelector('.c-header-hamburger');
  if(!HamburgerButton) return;
  const HamburgerMenu = document.querySelector('.c-header-menu-sp');
  const navItems = document.querySelectorAll('.c-header-menu-sp__item');
  if(!navItems.length === 0) return;

  /**
   * ハンバーガーメニューの表示
   */
  HamburgerButton.addEventListener('click', () => {
    HamburgerButton.classList.toggle('is-active');
    HamburgerMenu.classList.toggle('is-active');
  });
}