export function init() {
  const supportCard = document.querySelector('.c-support-card');
  if(supportCard === null) return;

  const privacyBlocks = supportCard.querySelectorAll('.c-privacy-block');

  privacyBlocks.forEach((privacyBlock) => {
    const checkbox = privacyBlock.querySelector('.c-checkbox input');
    const button = privacyBlock.querySelector('.c-button');

    checkbox.addEventListener('change', () => {
      if(checkbox.checked) {
        button.classList.remove("c-button--disabled");
      } else {
        button.classList.add("c-button--disabled");
      }
    })
  })
}