import * as accordion from './common/accordion';
import * as addTag from './common/addTag';
import * as form from './common/form';
import * as headerHamburger from './common/header-hamburger';
import * as headerPC from './common/header-pc';
import * as headerSP from './common/header-sp';
import * as loadedHeader from './common/loaded-header';
import * as observer from './common/observer';
import * as productDetail from './common/product-detail';
import * as toggleDownloadButton from './common/toggle-download-button';
import * as wpHeaderPC from './common/wp-header-pc';
import * as wpHeaderSP from './common/wp-header-sp';

function initializeProductDetail() {
  return new Promise((resolve) => {
    addTag.init();
    form.init();
    headerHamburger.init();
    headerPC.init();
    headerSP.init();
    productDetail.init();
    toggleDownloadButton.init();
    wpHeaderPC.init();
    wpHeaderSP.init();
    resolve();
  });
}

window.addEventListener('DOMContentLoaded', () => {
  initializeProductDetail().then(() => {
    accordion.init();
    loadedHeader.init();
    observer.init();
  });
});
