import ResizeObserver from 'resize-observer-polyfill';

export function init() {
  const triggers = document.querySelectorAll('.c-accordion-trigger');

  toggleAccordion(triggers);
  openAccordion(triggers);
  watchAccordion(triggers);

  window.addEventListener('resize', () => {
    resizeAccordion(triggers);
  });
}

function resizeAccordion(triggers) {
  triggers.forEach((trigger) => {
    // メニューが開いてない場合は回避
    if(!(trigger.classList.contains('is-active'))) return; 

    const targetContent = trigger.nextElementSibling;
    let targetContentHeight = targetContent.getBoundingClientRect().height
    let maxHeight = targetContent.children[0].getBoundingClientRect().height;

    // 元の高さとリサイズしたときの高さが同じ場合は回避
    if(targetContentHeight == maxHeight) return;

    targetContent.style.maxHeight = maxHeight + 'px';
    targetContent.style.height = maxHeight + 'px';
  });
}

function toggleAccordion(triggers) {
  triggers.forEach((trigger) =>  {

    trigger.addEventListener('click', (e) => {
      const targetButton = trigger;
      const targetContent = targetButton.nextElementSibling;
      targetContent.style.overflow = 'hidden';

      // radioボタン、checkbox対策
      // 上記クリックイベントとアコーディオン機能がかぶるため動作を回避する
      if(!e.target.classList.contains('c-accordion-trigger')) {
        return;
      }

      if(!(targetContent.classList.contains('is-active'))) {
        targetContent.style.display = 'block';
        targetContent.style.maxHeight = '';
        targetContent.style.height = '';
        let maxHeight = targetContent.getBoundingClientRect().height;
        targetContent.style.maxHeight = '0px';
        targetContent.style.height = '0px';
      
        targetButton.classList.add('is-active');
        targetContent.classList.add('is-active');
        // アニメーション遅延
        setTimeout(() => {
          targetContent.style.display = 'block';
          targetContent.style.maxHeight = maxHeight + 'px';
          targetContent.style.height = maxHeight + 'px';
        }, 10)
        return;
      }

      targetButton.classList.remove('is-active');
      targetContent.classList.remove('is-active');
      targetContent.style.maxHeight = '0px';
      targetContent.style.height = '0px';
    })
  })
}

// すでにメニューが開いてる場合
function openAccordion(triggers) {
  triggers.forEach((trigger) =>  {
    if(trigger.classList.contains('is-active')) {
      const targetContent = trigger.nextElementSibling;
      let maxHeight = targetContent.getBoundingClientRect().height;
      targetContent.style.display = 'block';
      targetContent.style.maxHeight = maxHeight + 'px';
      targetContent.style.height = maxHeight + 'px';
    }
  })
}

function watchAccordion(triggers) {
  triggers.forEach((trigger) =>  {
    const targetContent = trigger.nextElementSibling;
    if(!targetContent) return;
    const targetContentInner = targetContent.children[0];

    // アコーディオンの高さに変更があった場合の処理
    const observer = new ResizeObserver((entries) => {
      // アコーディオンが開いてる場合の処理
      if((targetContent.classList.contains('is-active'))) {
        let maxHeight = entries[0].target.getBoundingClientRect().height;
        targetContent.style.maxHeight = maxHeight + 'px';
        targetContent.style.height = maxHeight + 'px';
        return;
      }
    })
  
    // 監視
    observer.observe(targetContentInner);
  })
}