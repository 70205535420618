export function init() {
  const MenuItems = document.querySelectorAll('.c-header-menu__item');
  if(MenuItems.length === 0) return;
  const Overlay = document.querySelector('.c-header-menu__overlay');

  /**
   * メガメニューの表示
   */
  function addOverlay() {
    Overlay.classList.add('is-active');
  }

  /**
   * メガメニューの非表示
   */
  function removeOverlay() {
    Overlay.classList.remove('is-active');
  }
  
  MenuItems.forEach((MenuItem) => {
    const MegaMenu = MenuItem.querySelector('.c-header-megamenu');
    if(!MegaMenu) return;

    MenuItem.addEventListener('mouseenter', () => {
      addOverlay()
    });
    MenuItem.addEventListener('mouseleave', () => {
      removeOverlay()
    })

    MegaMenu.addEventListener('mouseenter', () => {
      addOverlay()
    });
    MegaMenu.addEventListener('mouseleave', () => {
      removeOverlay()
    })
  });
}
