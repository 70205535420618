export function init() {
  let radioButtons;
  radioButtons = document.querySelectorAll('.js-form-content-radio');
  if(radioButtons.length === 0) {
    radioButtons = document.querySelectorAll('.c-contact-form__input-area--radio label');
    if(radioButtons.length === 0) return;
  }
  const targetInput = document.querySelector('.js-form-content-input');
  targetInput.disabled = true;

  // お問い合わせ項目の「その他のお問い合わせ」ボタンの挙動
  radioButtons.forEach(radioButton => {
    radioButton.addEventListener('click', (e) => {
      const targetValue = e.target.value;
      if(targetValue === "その他のお問い合わせ（下記の欄にご入力ください）") {
        targetInput.disabled = false;
        targetInput.classList.remove('c-input--disabled');
      } else {
        if(targetInput.disabled === true) return;
        targetInput.disabled = true;
        targetInput.classList.add('c-input--disabled');
      }
    })
  });
}
